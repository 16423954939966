module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Samuel Rambaud","short_name":"Sr !","start_url":"/","background_color":"#333333","theme_color":"#333333","display":"fullscreen","icon":"static/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a10fec64d184edf8220626d9a2f64d85"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
